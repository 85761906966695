@import "dds__version";
@import "dds__icon-variables";


@font-face {
  font-family: 'dds-icons';
  font-style: normal;
  font-weight: normal;
  src: url('//uicore.dellcdn.com/#{$version}/icons/dds-icons.woff2') format('woff2'), /* Pretty Modern Browsers */
     url('//uicore.dellcdn.com/#{$version}/icons/dds-icons.woff') format('woff'), /* Super Modern Browsers */
     url('//uicore.dellcdn.com/#{$version}/icons/dds-icons.ttf') format('truetype'), /* Safari, Android, iOS */
     url('//uicore.dellcdn.com/#{$version}/icons/dds-icons.eot'), /* IE9 Compat Modes */
     url('//uicore.dellcdn.com/#{$version}/icons/dds-icons.eot?#iefix') format('embedded-opentype'); /* IE6-IE8 */
  
}

//SVG Icon Usage
.dds__flag-icons {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.dds__svg-icons {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

// Icon css
.dds__icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dds-icons';
  // speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//=======================================================
//------------ code for grid display only ---------------
//=======================================================
.dds__icon-grid-container {
  height: 100%;
  outline: 0.0625rem solid $dell-ash;
  padding: 0.625rem;
  font-size: 3rem;
  align-items: center;

  .dds__icon-grid-item {
    margin: 0.625rem;
    font-size: 3rem;
    float: left;
    color: $dell-blue;
  }

  .dds__icon-grid-text {
    font-size: 1rem;
    padding: 0.3125rem;
    margin-bottom: .0325rem;
  }

  .dds__icon-grid-text-muted {
    font-size: .675rem;
    padding: 0.3125rem;
    color: $dell-gray;
  }
}

//=======================================================
//=======================================================

.dds__icon-svg-grid-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  outline: 0.0625rem solid $dell-ash;
  padding: 0.625rem;
  font-size: 3rem;

  .dds__icon-svg-grid-item {
    display: inherit;
    align-items: center;
    margin: 0.625rem;
    font-size: 3rem;
    color: $dell-blue;
  }

  .dds__icon-grid-text {
    font-size: 1rem;
    padding: 0.3125rem;
    margin-bottom: .0325rem;
  }

  .dds__icon-grid-text-muted {
    font-size: .675rem;
    padding: 0.3125rem;
    color: $dell-gray;
  }

  ol {
    padding-inline-start: 10px;
  }
}


.dds__d-display {
  &:before {
    content: $dds__d-display; 
  }
}
.dds__hinge {
  &:before {
    content: $dds__hinge; 
  }
}
.dds__accessibility {
  &:before {
    content: $dds__accessibility; 
  }
}
.dds__add-cir {
  &:before {
    content: $dds__add-cir; 
  }
}
.dds__add-sqr {
  &:before {
    content: $dds__add-sqr; 
  }
}
.dds__airplane {
  &:before {
    content: $dds__airplane; 
  }
}
.dds__alarm-bell {
  &:before {
    content: $dds__alarm-bell; 
  }
}
.dds__alarm-clock {
  &:before {
    content: $dds__alarm-clock; 
  }
}
.dds__alert-check-cir {
  &:before {
    content: $dds__alert-check-cir; 
  }
}
.dds__alert-check-sqr {
  &:before {
    content: $dds__alert-check-sqr; 
  }
}
.dds__alert-error {
  &:before {
    content: $dds__alert-error; 
  }
}
.dds__alert-info-cir {
  &:before {
    content: $dds__alert-info-cir; 
  }
}
.dds__alert-info-sqr {
  &:before {
    content: $dds__alert-info-sqr; 
  }
}
.dds__alert-notice {
  &:before {
    content: $dds__alert-notice; 
  }
}
.dds__app-window {
  &:before {
    content: $dds__app-window; 
  }
}
.dds__app-window-search {
  &:before {
    content: $dds__app-window-search; 
  }
}
.dds__arrange {
  &:before {
    content: $dds__arrange; 
  }
}
.dds__arrow-cir-down {
  &:before {
    content: $dds__arrow-cir-down; 
  }
}
.dds__arrow-cir-left {
  &:before {
    content: $dds__arrow-cir-left; 
  }
}
.dds__arrow-cir-right {
  &:before {
    content: $dds__arrow-cir-right; 
  }
}
.dds__arrow-cir-up {
  &:before {
    content: $dds__arrow-cir-up; 
  }
}
.dds__arrow-down {
  &:before {
    content: $dds__arrow-down; 
  }
}
.dds__arrow-export {
  &:before {
    content: $dds__arrow-export; 
  }
}
.dds__arrow-left {
  &:before {
    content: $dds__arrow-left; 
  }
}
.dds__arrow-right {
  &:before {
    content: $dds__arrow-right; 
  }
}
.dds__arrow-tri-down {
  &:before {
    content: $dds__arrow-tri-down; 
  }
}
.dds__arrow-tri-left {
  &:before {
    content: $dds__arrow-tri-left; 
  }
}
.dds__arrow-tri-right {
  &:before {
    content: $dds__arrow-tri-right; 
  }
}
.dds__arrow-tri-solid-right {
  &:before {
    content: $dds__arrow-tri-solid-right; 
  }
}
.dds__arrow-tri-up {
  &:before {
    content: $dds__arrow-tri-up; 
  }
}
.dds__arrow-up {
  &:before {
    content: $dds__arrow-up; 
  }
}
.dds__assistance {
  &:before {
    content: $dds__assistance; 
  }
}
.dds__audio-card {
  &:before {
    content: $dds__audio-card; 
  }
}
.dds__audio-speaker {
  &:before {
    content: $dds__audio-speaker; 
  }
}
.dds__audio-speaker-levels {
  &:before {
    content: $dds__audio-speaker-levels; 
  }
}
.dds__audio-speaker-mute {
  &:before {
    content: $dds__audio-speaker-mute; 
  }
}
.dds__audio-speaker-off {
  &:before {
    content: $dds__audio-speaker-off; 
  }
}
.dds__audio-speaker-on {
  &:before {
    content: $dds__audio-speaker-on; 
  }
}
.dds__award-certificate {
  &:before {
    content: $dds__award-certificate; 
  }
}
.dds__award-trophy {
  &:before {
    content: $dds__award-trophy; 
  }
}
.dds__battery-charge {
  &:before {
    content: $dds__battery-charge; 
  }
}
.dds__battery-empty {
  &:before {
    content: $dds__battery-empty; 
  }
}
.dds__battery-horiz-empty {
  &:before {
    content: $dds__battery-horiz-empty; 
  }
}
.dds__battery-horiz-full {
  &:before {
    content: $dds__battery-horiz-full; 
  }
}
.dds__battery-horiz-half {
  &:before {
    content: $dds__battery-horiz-half; 
  }
}
.dds__battery-vert-full {
  &:before {
    content: $dds__battery-vert-full; 
  }
}
.dds__battery-vert-half {
  &:before {
    content: $dds__battery-vert-half; 
  }
}
.dds__bluetooth {
  &:before {
    content: $dds__bluetooth; 
  }
}
.dds__bolt {
  &:before {
    content: $dds__bolt; 
  }
}
.dds__bookmark {
  &:before {
    content: $dds__bookmark; 
  }
}
.dds__book-open {
  &:before {
    content: $dds__book-open; 
  }
}
.dds__brand-alienware {
  &:before {
    content: $dds__brand-alienware; 
  }
}
.dds__brand-dell {
  &:before {
    content: $dds__brand-dell; 
  }
}
.dds__brand-dellemc {
  &:before {
    content: $dds__brand-dellemc; 
  }
}
.dds__brand-dell-gaming {
  &:before {
    content: $dds__brand-dell-gaming; 
  }
}
.dds__brand-delltechnologies {
  &:before {
    content: $dds__brand-delltechnologies; 
  }
}
.dds__brand-delltechnologiesvert {
  &:before {
    content: $dds__brand-delltechnologiesvert; 
  }
}
.dds__brand-windows {
  &:before {
    content: $dds__brand-windows; 
  }
}
.dds__brightness {
  &:before {
    content: $dds__brightness; 
  }
}
.dds__calculator {
  &:before {
    content: $dds__calculator; 
  }
}
.dds__calendar {
  &:before {
    content: $dds__calendar; 
  }
}
.dds__calendar-search {
  &:before {
    content: $dds__calendar-search; 
  }
}
.dds__camera {
  &:before {
    content: $dds__camera; 
  }
}
.dds__cancelled {
  &:before {
    content: $dds__cancelled; 
  }
}
.dds__capture-image {
  &:before {
    content: $dds__capture-image; 
  }
}
.dds__card-credit {
  &:before {
    content: $dds__card-credit; 
  }
}
.dds__card-info {
  &:before {
    content: $dds__card-info; 
  }
}
.dds__card-swipe-left {
  &:before {
    content: $dds__card-swipe-left; 
  }
}
.dds__card-swipe-right {
  &:before {
    content: $dds__card-swipe-right; 
  }
}
.dds__carry-case {
  &:before {
    content: $dds__carry-case; 
  }
}
.dds__cart {
  &:before {
    content: $dds__cart; 
  }
}
.dds__chain-link {
  &:before {
    content: $dds__chain-link; 
  }
}
.dds__charge-back {
  &:before {
    content: $dds__charge-back; 
  }
}
.dds__charge-back-folder {
  &:before {
    content: $dds__charge-back-folder; 
  }
}
.dds__charge-back-report {
  &:before {
    content: $dds__charge-back-report; 
  }
}
.dds__chart-bars-axis {
  &:before {
    content: $dds__chart-bars-axis; 
  }
}
.dds__chart-bars-box {
  &:before {
    content: $dds__chart-bars-box; 
  }
}
.dds__chart-insights {
  &:before {
    content: $dds__chart-insights; 
  }
}
.dds__chat-dots {
  &:before {
    content: $dds__chat-dots; 
  }
}
.dds__check {
  &:before {
    content: $dds__check; 
  }
}
.dds__check-property {
  &:before {
    content: $dds__check-property; 
  }
}
.dds__chevron-cir-down {
  &:before {
    content: $dds__chevron-cir-down; 
  }
}
.dds__chevron-cir-left {
  &:before {
    content: $dds__chevron-cir-left; 
  }
}
.dds__chevron-cir-right {
  &:before {
    content: $dds__chevron-cir-right; 
  }
}
.dds__chevron-cir-up {
  &:before {
    content: $dds__chevron-cir-up; 
  }
}
.dds__chevron-down {
  &:before {
    content: $dds__chevron-down; 
  }
}
.dds__chevron-left {
  &:before {
    content: $dds__chevron-left; 
  }
}
.dds__chevron-right {
  &:before {
    content: $dds__chevron-right; 
  }
}
.dds__chevron-up {
  &:before {
    content: $dds__chevron-up; 
  }
}
.dds__chip-set {
  &:before {
    content: $dds__chip-set; 
  }
}
.dds__clipboard {
  &:before {
    content: $dds__clipboard; 
  }
}
.dds__clipboard-check {
  &:before {
    content: $dds__clipboard-check; 
  }
}
.dds__clipboard-lines {
  &:before {
    content: $dds__clipboard-lines; 
  }
}
.dds__clipboard-notes {
  &:before {
    content: $dds__clipboard-notes; 
  }
}
.dds__clipboard-remove {
  &:before {
    content: $dds__clipboard-remove; 
  }
}
.dds__clipboard-search {
  &:before {
    content: $dds__clipboard-search; 
  }
}
.dds__clock {
  &:before {
    content: $dds__clock; 
  }
}
.dds__clock-arrow-left {
  &:before {
    content: $dds__clock-arrow-left; 
  }
}
.dds__clock-arrow-right {
  &:before {
    content: $dds__clock-arrow-right; 
  }
}
.dds__clone {
  &:before {
    content: $dds__clone; 
  }
}
.dds__close-x {
  &:before {
    content: $dds__close-x; 
  }
}
.dds__close-back {
  &:before {
    content: $dds__close-back; 
  }
}
.dds__close-cir {
  &:before {
    content: $dds__close-cir; 
  }
}
.dds__close-sqr {
  &:before {
    content: $dds__close-sqr; 
  }
}
.dds__cloud {
  &:before {
    content: $dds__cloud; 
  }
}
.dds__cloud-check {
  &:before {
    content: $dds__cloud-check; 
  }
}
.dds__cloud-document {
  &:before {
    content: $dds__cloud-document; 
  }
}
.dds__cloud-iq {
  &:before {
    content: $dds__cloud-iq; 
  }
}
.dds__cloud-restore {
  &:before {
    content: $dds__cloud-restore; 
  }
}
.dds__cloud-upload {
  &:before {
    content: $dds__cloud-upload; 
  }
}
.dds__cloud-wireless {
  &:before {
    content: $dds__cloud-wireless; 
  }
}
.dds__collapse-down {
  &:before {
    content: $dds__collapse-down; 
  }
}
.dds__collapse-down-sqr {
  &:before {
    content: $dds__collapse-down-sqr; 
  }
}
.dds__collapse-up {
  &:before {
    content: $dds__collapse-up; 
  }
}
.dds__collapse-up-sqr {
  &:before {
    content: $dds__collapse-up-sqr; 
  }
}
.dds__column-add {
  &:before {
    content: $dds__column-add; 
  }
}
.dds__comment {
  &:before {
    content: $dds__comment; 
  }
}
.dds__comment-add {
  &:before {
    content: $dds__comment-add; 
  }
}
.dds__comment-check {
  &:before {
    content: $dds__comment-check; 
  }
}
.dds__compare {
  &:before {
    content: $dds__compare; 
  }
}
.dds__conversation {
  &:before {
    content: $dds__conversation; 
  }
}
.dds__conversation-cir {
  &:before {
    content: $dds__conversation-cir; 
  }
}
.dds__copy {
  &:before {
    content: $dds__copy; 
  }
}
.dds__copy-alt {
  &:before {
    content: $dds__copy-alt; 
  }
}
.dds__cube {
  &:before {
    content: $dds__cube; 
  }
}
.dds__currency {
  &:before {
    content: $dds__currency; 
  }
}
.dds__currency-coins {
  &:before {
    content: $dds__currency-coins; 
  }
}
.dds__cursor {
  &:before {
    content: $dds__cursor; 
  }
}
.dds__cursor-clock {
  &:before {
    content: $dds__cursor-clock; 
  }
}
.dds__dashboard {
  &:before {
    content: $dds__dashboard; 
  }
}
.dds__data-center {
  &:before {
    content: $dds__data-center; 
  }
}
.dds__data-compute {
  &:before {
    content: $dds__data-compute; 
  }
}
.dds__datastore {
  &:before {
    content: $dds__datastore; 
  }
}
.dds__debug {
  &:before {
    content: $dds__debug; 
  }
}
.dds__deploy {
  &:before {
    content: $dds__deploy; 
  }
}
.dds__device-data-center {
  &:before {
    content: $dds__device-data-center; 
  }
}
.dds__device-laptop {
  &:before {
    content: $dds__device-laptop; 
  }
}
.dds__device-laptop-detachable {
  &:before {
    content: $dds__device-laptop-detachable; 
  }
}
.dds__device-lock-screen {
  &:before {
    content: $dds__device-lock-screen; 
  }
}
.dds__device-mobile {
  &:before {
    content: $dds__device-mobile; 
  }
}
.dds__device-modular {
  &:before {
    content: $dds__device-modular; 
  }
}
.dds__device-monitor {
  &:before {
    content: $dds__device-monitor; 
  }
}
.dds__device-nodes {
  &:before {
    content: $dds__device-nodes; 
  }
}
.dds__device-screen-size {
  &:before {
    content: $dds__device-screen-size; 
  }
}
.dds__device-server {
  &:before {
    content: $dds__device-server; 
  }
}
.dds__device-server-tower {
  &:before {
    content: $dds__device-server-tower; 
  }
}
.dds__device-storage-array {
  &:before {
    content: $dds__device-storage-array; 
  }
}
.dds__device-switch-blade {
  &:before {
    content: $dds__device-switch-blade; 
  }
}
.dds__device-switch-blade-2U {
  &:before {
    content: $dds__device-switch-blade-2U; 
  }
}
.dds__device-tablet-horiz {
  &:before {
    content: $dds__device-tablet-horiz; 
  }
}
.dds__device-tablet-vert {
  &:before {
    content: $dds__device-tablet-vert; 
  }
}
.dds__device-thin-client {
  &:before {
    content: $dds__device-thin-client; 
  }
}
.dds__diagnostic {
  &:before {
    content: $dds__diagnostic; 
  }
}
.dds__dimensions-weight {
  &:before {
    content: $dds__dimensions-weight; 
  }
}
.dds__disc-arrow-down {
  &:before {
    content: $dds__disc-arrow-down; 
  }
}
.dds__disc-arrow-up {
  &:before {
    content: $dds__disc-arrow-up; 
  }
}
.dds__disc-check {
  &:before {
    content: $dds__disc-check; 
  }
}
.dds__disc-insert {
  &:before {
    content: $dds__disc-insert; 
  }
}
.dds__disc-recovery {
  &:before {
    content: $dds__disc-recovery; 
  }
}
.dds__disc-secure {
  &:before {
    content: $dds__disc-secure; 
  }
}
.dds__disc-software {
  &:before {
    content: $dds__disc-software; 
  }
}
.dds__disc-system {
  &:before {
    content: $dds__disc-system; 
  }
}
.dds__doc-add {
  &:before {
    content: $dds__doc-add; 
  }
}
.dds__doc-arrow-left {
  &:before {
    content: $dds__doc-arrow-left; 
  }
}
.dds__doc-arrow-right {
  &:before {
    content: $dds__doc-arrow-right; 
  }
}
.dds__doc-check {
  &:before {
    content: $dds__doc-check; 
  }
}
.dds__doc-cube {
  &:before {
    content: $dds__doc-cube; 
  }
}
.dds__doc-lines {
  &:before {
    content: $dds__doc-lines; 
  }
}
.dds__doc-manual {
  &:before {
    content: $dds__doc-manual; 
  }
}
.dds__doc-reports {
  &:before {
    content: $dds__doc-reports; 
  }
}
.dds__docs-copy-alt {
  &:before {
    content: $dds__docs-copy-alt; 
  }
}
.dds__doc-search {
  &:before {
    content: $dds__doc-search; 
  }
}
.dds__doc-secure {
  &:before {
    content: $dds__doc-secure; 
  }
}
.dds__doc-settings {
  &:before {
    content: $dds__doc-settings; 
  }
}
.dds__doc-support {
  &:before {
    content: $dds__doc-support; 
  }
}
.dds__document {
  &:before {
    content: $dds__document; 
  }
}
.dds__download {
  &:before {
    content: $dds__download; 
  }
}
.dds__download-alt {
  &:before {
    content: $dds__download-alt; 
  }
}
.dds__ear {
  &:before {
    content: $dds__ear; 
  }
}
.dds__ellipsis {
  &:before {
    content: $dds__ellipsis; 
  }
}
.dds__energy-efficient {
  &:before {
    content: $dds__energy-efficient; 
  }
}
.dds__escalate-tri {
  &:before {
    content: $dds__escalate-tri; 
  }
}
.dds__exit-door {
  &:before {
    content: $dds__exit-door; 
  }
}
.dds__expand {
  &:before {
    content: $dds__expand; 
  }
}
.dds__eye-view-off {
  &:before {
    content: $dds__eye-view-off; 
  }
}
.dds__eye-view-on {
  &:before {
    content: $dds__eye-view-on; 
  }
}
.dds__fan {
  &:before {
    content: $dds__fan; 
  }
}
.dds__filter {
  &:before {
    content: $dds__filter; 
  }
}
.dds__filter-add {
  &:before {
    content: $dds__filter-add; 
  }
}
.dds__filter-clear {
  &:before {
    content: $dds__filter-clear; 
  }
}
.dds__filter-move {
  &:before {
    content: $dds__filter-move; 
  }
}
.dds__filter-remove {
  &:before {
    content: $dds__filter-remove; 
  }
}
.dds__fingerprint {
  &:before {
    content: $dds__fingerprint; 
  }
}
.dds__flag {
  &:before {
    content: $dds__flag; 
  }
}
.dds__flame {
  &:before {
    content: $dds__flame; 
  }
}
.dds__flash-drive {
  &:before {
    content: $dds__flash-drive; 
  }
}
.dds__folder {
  &:before {
    content: $dds__folder; 
  }
}
.dds__folder-add {
  &:before {
    content: $dds__folder-add; 
  }
}
.dds__folder-arrow-right {
  &:before {
    content: $dds__folder-arrow-right; 
  }
}
.dds__folder-arrow-up {
  &:before {
    content: $dds__folder-arrow-up; 
  }
}
.dds__folder-media {
  &:before {
    content: $dds__folder-media; 
  }
}
.dds__full-screen {
  &:before {
    content: $dds__full-screen; 
  }
}
.dds__gear {
  &:before {
    content: $dds__gear; 
  }
}
.dds__gear-arrow {
  &:before {
    content: $dds__gear-arrow; 
  }
}
.dds__gear-wrench {
  &:before {
    content: $dds__gear-wrench; 
  }
}
.dds__gift-card {
  &:before {
    content: $dds__gift-card; 
  }
}
.dds__globe-simple {
  &:before {
    content: $dds__globe-simple; 
  }
}
.dds__graphics-card {
  &:before {
    content: $dds__graphics-card; 
  }
}
.dds__handle {
  &:before {
    content: $dds__handle; 
  }
}
.dds__hand-touch {
  &:before {
    content: $dds__hand-touch; 
  }
}
.dds__hand-touch-screen {
  &:before {
    content: $dds__hand-touch-screen; 
  }
}
.dds__hard-drive {
  &:before {
    content: $dds__hard-drive; 
  }
}
.dds__hd-generic {
  &:before {
    content: $dds__hd-generic; 
  }
}
.dds__headphones {
  &:before {
    content: $dds__headphones; 
  }
}
.dds__headset {
  &:before {
    content: $dds__headset; 
  }
}
.dds__heat {
  &:before {
    content: $dds__heat; 
  }
}
.dds__help-cir {
  &:before {
    content: $dds__help-cir; 
  }
}
.dds__help-sqr {
  &:before {
    content: $dds__help-sqr; 
  }
}
.dds__home {
  &:before {
    content: $dds__home; 
  }
}
.dds__host-server {
  &:before {
    content: $dds__host-server; 
  }
}
.dds__host-server-hex {
  &:before {
    content: $dds__host-server-hex; 
  }
}
.dds__host-server-rack {
  &:before {
    content: $dds__host-server-rack; 
  }
}
.dds__import {
  &:before {
    content: $dds__import; 
  }
}
.dds__import-alt {
  &:before {
    content: $dds__import-alt; 
  }
}
.dds__inbox {
  &:before {
    content: $dds__inbox; 
  }
}
.dds__inbox-arrow-down {
  &:before {
    content: $dds__inbox-arrow-down; 
  }
}
.dds__infrastructure {
  &:before {
    content: $dds__infrastructure; 
  }
}
.dds__ink-toner {
  &:before {
    content: $dds__ink-toner; 
  }
}
.dds__key {
  &:before {
    content: $dds__key; 
  }
}
.dds__keyboard {
  &:before {
    content: $dds__keyboard; 
  }
}
.dds__keyboard-mouse {
  &:before {
    content: $dds__keyboard-mouse; 
  }
}
.dds__keypad {
  &:before {
    content: $dds__keypad; 
  }
}
.dds__laptop-generic {
  &:before {
    content: $dds__laptop-generic; 
  }
}
.dds__lcd {
  &:before {
    content: $dds__lcd; 
  }
}
.dds__lightbulb {
  &:before {
    content: $dds__lightbulb; 
  }
}
.dds__location {
  &:before {
    content: $dds__location; 
  }
}
.dds__location-play {
  &:before {
    content: $dds__location-play; 
  }
}
.dds__lock-closed {
  &:before {
    content: $dds__lock-closed; 
  }
}
.dds__lock-open {
  &:before {
    content: $dds__lock-open; 
  }
}
.dds__log-in {
  &:before {
    content: $dds__log-in; 
  }
}
.dds__log-out {
  &:before {
    content: $dds__log-out; 
  }
}
.dds__mail {
  &:before {
    content: $dds__mail; 
  }
}
.dds__mail-open {
  &:before {
    content: $dds__mail-open; 
  }
}
.dds__manage-info {
  &:before {
    content: $dds__manage-info; 
  }
}
.dds__management-software {
  &:before {
    content: $dds__management-software; 
  }
}
.dds__memory {
  &:before {
    content: $dds__memory; 
  }
}
.dds__memory-card-reader {
  &:before {
    content: $dds__memory-card-reader; 
  }
}
.dds__memory-sd-card {
  &:before {
    content: $dds__memory-sd-card; 
  }
}
.dds__menu-closed {
  &:before {
    content: $dds__menu-closed; 
  }
}
.dds__metadata {
  &:before {
    content: $dds__metadata; 
  }
}
.dds__microchip {
  &:before {
    content: $dds__microchip; 
  }
}
.dds__microphone-off {
  &:before {
    content: $dds__microphone-off; 
  }
}
.dds__microphone-on {
  &:before {
    content: $dds__microphone-on; 
  }
}
.dds__migrate {
  &:before {
    content: $dds__migrate; 
  }
}
.dds__minimize {
  &:before {
    content: $dds__minimize; 
  }
}
.dds__minus-minimize {
  &:before {
    content: $dds__minus-minimize; 
  }
}
.dds__missing-image {
  &:before {
    content: $dds__missing-image; 
  }
}
.dds__mouse {
  &:before {
    content: $dds__mouse; 
  }
}
.dds__mouse-cord {
  &:before {
    content: $dds__mouse-cord; 
  }
}
.dds__movie-clapper {
  &:before {
    content: $dds__movie-clapper; 
  }
}
.dds__movie-clapper-play {
  &:before {
    content: $dds__movie-clapper-play; 
  }
}
.dds__music-note {
  &:before {
    content: $dds__music-note; 
  }
}
.dds__network-connected {
  &:before {
    content: $dds__network-connected; 
  }
}
.dds__network-horiz {
  &:before {
    content: $dds__network-horiz; 
  }
}
.dds__network-share {
  &:before {
    content: $dds__network-share; 
  }
}
.dds__network-vert {
  &:before {
    content: $dds__network-vert; 
  }
}
.dds__network-vert-disable {
  &:before {
    content: $dds__network-vert-disable; 
  }
}
.dds__objects {
  &:before {
    content: $dds__objects; 
  }
}
.dds__office {
  &:before {
    content: $dds__office; 
  }
}
.dds__overflow {
  &:before {
    content: $dds__overflow; 
  }
}
.dds__package-closed {
  &:before {
    content: $dds__package-closed; 
  }
}
.dds__package-open {
  &:before {
    content: $dds__package-open; 
  }
}
.dds__paint-brush {
  &:before {
    content: $dds__paint-brush; 
  }
}
.dds__paint-palette {
  &:before {
    content: $dds__paint-palette; 
  }
}
.dds__paper-clip {
  &:before {
    content: $dds__paper-clip; 
  }
}
.dds__partners {
  &:before {
    content: $dds__partners; 
  }
}
.dds__password {
  &:before {
    content: $dds__password; 
  }
}
.dds__pause {
  &:before {
    content: $dds__pause; 
  }
}
.dds__pause-sqr {
  &:before {
    content: $dds__pause-sqr; 
  }
}
.dds__pencil {
  &:before {
    content: $dds__pencil; 
  }
}
.dds__pencil-check {
  &:before {
    content: $dds__pencil-check; 
  }
}
.dds__pencil-mark {
  &:before {
    content: $dds__pencil-mark; 
  }
}
.dds__pencil-remove {
  &:before {
    content: $dds__pencil-remove; 
  }
}
.dds__performance {
  &:before {
    content: $dds__performance; 
  }
}
.dds__phone {
  &:before {
    content: $dds__phone; 
  }
}
.dds__pie-chart {
  &:before {
    content: $dds__pie-chart; 
  }
}
.dds__pie-chart-solid {
  &:before {
    content: $dds__pie-chart-solid; 
  }
}
.dds__pin {
  &:before {
    content: $dds__pin; 
  }
}
.dds__play-cir {
  &:before {
    content: $dds__play-cir; 
  }
}
.dds__play-sqr {
  &:before {
    content: $dds__play-sqr; 
  }
}
.dds__plus-add {
  &:before {
    content: $dds__plus-add; 
  }
}
.dds__popout-double {
  &:before {
    content: $dds__popout-double; 
  }
}
.dds__popout-share {
  &:before {
    content: $dds__popout-share; 
  }
}
.dds__popout-window {
  &:before {
    content: $dds__popout-window; 
  }
}
.dds__popout-window-arrow {
  &:before {
    content: $dds__popout-window-arrow; 
  }
}
.dds__pop-up-arrow {
  &:before {
    content: $dds__pop-up-arrow; 
  }
}
.dds__pop-up-arrow-corner {
  &:before {
    content: $dds__pop-up-arrow-corner; 
  }
}
.dds__port-double {
  &:before {
    content: $dds__port-double; 
  }
}
.dds__power-off {
  &:before {
    content: $dds__power-off; 
  }
}
.dds__power-on {
  &:before {
    content: $dds__power-on; 
  }
}
.dds__power-supply {
  &:before {
    content: $dds__power-supply; 
  }
}
.dds__power-supply-cord {
  &:before {
    content: $dds__power-supply-cord; 
  }
}
.dds__power-supply-double {
  &:before {
    content: $dds__power-supply-double; 
  }
}
.dds__printer {
  &:before {
    content: $dds__printer; 
  }
}
.dds__processor {
  &:before {
    content: $dds__processor; 
  }
}
.dds__productivity {
  &:before {
    content: $dds__productivity; 
  }
}
.dds__projector {
  &:before {
    content: $dds__projector; 
  }
}
.dds__protractor {
  &:before {
    content: $dds__protractor; 
  }
}
.dds__pulse {
  &:before {
    content: $dds__pulse; 
  }
}
.dds__puzzle {
  &:before {
    content: $dds__puzzle; 
  }
}
.dds__qos {
  &:before {
    content: $dds__qos; 
  }
}
.dds__rack {
  &:before {
    content: $dds__rack; 
  }
}
.dds__recycle {
  &:before {
    content: $dds__recycle; 
  }
}
.dds__recycle-bin {
  &:before {
    content: $dds__recycle-bin; 
  }
}
.dds__redo {
  &:before {
    content: $dds__redo; 
  }
}
.dds__redo-move {
  &:before {
    content: $dds__redo-move; 
  }
}
.dds__refresh-spin {
  &:before {
    content: $dds__refresh-spin; 
  }
}
.dds__removable-storage-usb {
  &:before {
    content: $dds__removable-storage-usb; 
  }
}
.dds__remove-cir {
  &:before {
    content: $dds__remove-cir; 
  }
}
.dds__remove-sqr {
  &:before {
    content: $dds__remove-sqr; 
  }
}
.dds__reset {
  &:before {
    content: $dds__reset; 
  }
}
.dds__restore {
  &:before {
    content: $dds__restore; 
  }
}
.dds__rss {
  &:before {
    content: $dds__rss; 
  }
}
.dds__save {
  &:before {
    content: $dds__save; 
  }
}
.dds__save-disk {
  &:before {
    content: $dds__save-disk; 
  }
}
.dds__scale {
  &:before {
    content: $dds__scale; 
  }
}
.dds__search {
  &:before {
    content: $dds__search; 
  }
}
.dds__search-arrow-left {
  &:before {
    content: $dds__search-arrow-left; 
  }
}
.dds__search-arrow-right {
  &:before {
    content: $dds__search-arrow-right; 
  }
}
.dds__search-back {
  &:before {
    content: $dds__search-back; 
  }
}
.dds__search-forward {
  &:before {
    content: $dds__search-forward; 
  }
}
.dds__server-rack-arrow-right {
  &:before {
    content: $dds__server-rack-arrow-right; 
  }
}
.dds__server-rack-search {
  &:before {
    content: $dds__server-rack-search; 
  }
}
.dds__server-rack-support {
  &:before {
    content: $dds__server-rack-support; 
  }
}
.dds__share {
  &:before {
    content: $dds__share; 
  }
}
.dds__shield {
  &:before {
    content: $dds__shield; 
  }
}
.dds__shield-check {
  &:before {
    content: $dds__shield-check; 
  }
}
.dds__shield-protect {
  &:before {
    content: $dds__shield-protect; 
  }
}
.dds__shipping {
  &:before {
    content: $dds__shipping; 
  }
}
.dds__skip-forward {
  &:before {
    content: $dds__skip-forward; 
  }
}
.dds__skip-to-end {
  &:before {
    content: $dds__skip-to-end; 
  }
}
.dds__skip-to-front {
  &:before {
    content: $dds__skip-to-front; 
  }
}
.dds__slider-horiz {
  &:before {
    content: $dds__slider-horiz; 
  }
}
.dds__slider-vert {
  &:before {
    content: $dds__slider-vert; 
  }
}
.dds__smart-select {
  &:before {
    content: $dds__smart-select; 
  }
}
.dds__snapshot {
  &:before {
    content: $dds__snapshot; 
  }
}
.dds__snowflake {
  &:before {
    content: $dds__snowflake; 
  }
}
.dds__social-networking {
  &:before {
    content: $dds__social-networking; 
  }
}
.dds__sort-az {
  &:before {
    content: $dds__sort-az; 
  }
}
.dds__sort-horiz {
  &:before {
    content: $dds__sort-horiz; 
  }
}
.dds__sort-vert {
  &:before {
    content: $dds__sort-vert; 
  }
}
.dds__sort-za {
  &:before {
    content: $dds__sort-za; 
  }
}
.dds__speaker-double {
  &:before {
    content: $dds__speaker-double; 
  }
}
.dds__stack {
  &:before {
    content: $dds__stack; 
  }
}
.dds__star {
  &:before {
    content: $dds__star; 
  }
}
.dds__start-up {
  &:before {
    content: $dds__start-up; 
  }
}
.dds__stop {
  &:before {
    content: $dds__stop; 
  }
}
.dds__suspend {
  &:before {
    content: $dds__suspend; 
  }
}
.dds__sync-horiz {
  &:before {
    content: $dds__sync-horiz; 
  }
}
.dds__sync-vert {
  &:before {
    content: $dds__sync-vert; 
  }
}
.dds__tag {
  &:before {
    content: $dds__tag; 
  }
}
.dds__tag-deal {
  &:before {
    content: $dds__tag-deal; 
  }
}
.dds__task-check {
  &:before {
    content: $dds__task-check; 
  }
}
.dds__task-check-progress {
  &:before {
    content: $dds__task-check-progress; 
  }
}
.dds__thumb-down {
  &:before {
    content: $dds__thumb-down; 
  }
}
.dds__thumb-up {
  &:before {
    content: $dds__thumb-up; 
  }
}
.dds__toolbox {
  &:before {
    content: $dds__toolbox; 
  }
}
.dds__touch-pad {
  &:before {
    content: $dds__touch-pad; 
  }
}
.dds__trash {
  &:before {
    content: $dds__trash; 
  }
}
.dds__tv {
  &:before {
    content: $dds__tv; 
  }
}
.dds__undo {
  &:before {
    content: $dds__undo; 
  }
}
.dds__undo-move {
  &:before {
    content: $dds__undo-move; 
  }
}
.dds__upload {
  &:before {
    content: $dds__upload; 
  }
}
.dds__usb {
  &:before {
    content: $dds__usb; 
  }
}
.dds__user {
  &:before {
    content: $dds__user; 
  }
}
.dds__user-about {
  &:before {
    content: $dds__user-about; 
  }
}
.dds__user-add {
  &:before {
    content: $dds__user-add; 
  }
}
.dds__user-check {
  &:before {
    content: $dds__user-check; 
  }
}
.dds__user-cir {
  &:before {
    content: $dds__user-cir; 
  }
}
.dds__user-cir-add {
  &:before {
    content: $dds__user-cir-add; 
  }
}
.dds__user-cir-remove {
  &:before {
    content: $dds__user-cir-remove; 
  }
}
.dds__user-contact {
  &:before {
    content: $dds__user-contact; 
  }
}
.dds__user-directory {
  &:before {
    content: $dds__user-directory; 
  }
}
.dds__user-disable {
  &:before {
    content: $dds__user-disable; 
  }
}
.dds__user-group {
  &:before {
    content: $dds__user-group; 
  }
}
.dds__user-group-chat {
  &:before {
    content: $dds__user-group-chat; 
  }
}
.dds__user-group-disable {
  &:before {
    content: $dds__user-group-disable; 
  }
}
.dds__user-groups {
  &:before {
    content: $dds__user-groups; 
  }
}
.dds__user-groups-disable {
  &:before {
    content: $dds__user-groups-disable; 
  }
}
.dds__user-lock {
  &:before {
    content: $dds__user-lock; 
  }
}
.dds__user-remove {
  &:before {
    content: $dds__user-remove; 
  }
}
.dds__user-search {
  &:before {
    content: $dds__user-search; 
  }
}
.dds__user-unlock {
  &:before {
    content: $dds__user-unlock; 
  }
}
.dds__video-card {
  &:before {
    content: $dds__video-card; 
  }
}
.dds__view-column {
  &:before {
    content: $dds__view-column; 
  }
}
.dds__view-detailed {
  &:before {
    content: $dds__view-detailed; 
  }
}
.dds__view-grid {
  &:before {
    content: $dds__view-grid; 
  }
}
.dds__view-grid-alt {
  &:before {
    content: $dds__view-grid-alt; 
  }
}
.dds__view-list {
  &:before {
    content: $dds__view-list; 
  }
}
.dds__view-row {
  &:before {
    content: $dds__view-row; 
  }
}
.dds__view-table {
  &:before {
    content: $dds__view-table; 
  }
}
.dds__virtualization {
  &:before {
    content: $dds__virtualization; 
  }
}
.dds__volume {
  &:before {
    content: $dds__volume; 
  }
}
.dds__warranty {
  &:before {
    content: $dds__warranty; 
  }
}
.dds__web-camera {
  &:before {
    content: $dds__web-camera; 
  }
}
.dds__web-camera-hd {
  &:before {
    content: $dds__web-camera-hd; 
  }
}
.dds__weight-bell {
  &:before {
    content: $dds__weight-bell; 
  }
}
.dds__wifi {
  &:before {
    content: $dds__wifi; 
  }
}
.dds__wifi-simple {
  &:before {
    content: $dds__wifi-simple; 
  }
}
.dds__windows-support {
  &:before {
    content: $dds__windows-support; 
  }
}
.dds__wrench-cir {
  &:before {
    content: $dds__wrench-cir; 
  }
}
.dds__wrench-double {
  &:before {
    content: $dds__wrench-double; 
  }
}
.dds__wrench-manage {
  &:before {
    content: $dds__wrench-manage; 
  }
}
.dds__wrench-single {
  &:before {
    content: $dds__wrench-single; 
  }
}
.dds__wrench-tools {
  &:before {
    content: $dds__wrench-tools; 
  }
}
.dds__zoom-in {
  &:before {
    content: $dds__zoom-in; 
  }
}
.dds__zoom-out {
  &:before {
    content: $dds__zoom-out; 
  }
}
.dds__arrow-tri-solid-stacked {
  &:before {
    content: $dds__arrow-tri-solid-stacked; 
  }
}
.dds__popout-double-condense-vertical {
  &:before {
    content: $dds__popout-double-condense-vertical; 
  }
}
.dds__popout-double-vertical {
  &:before {
    content: $dds__popout-double-vertical; 
  }
}
.dds__popout-double-condense {
  &:before {
    content: $dds__popout-double-condense; 
  }
}
.dds__barbell {
  &:before {
    content: $dds__barbell; 
  }
}