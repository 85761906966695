@import "dds__version";

/* roboto-300 */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light');
    src: url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Light-v2.137.woff2') format('woff2'), /* Pretty Modern Browsers */ 
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Light-v2.137.woff') format('woff'), /* Super Modern Browsers */
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Light-v2.137.ttf')  format('truetype'), /* Safari, Android, iOS */
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Light-v2.137.eot'), /* IE9 Compat Modes */
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Light-v2.137.eot?#iefix') format('embedded-opentype'); /* IE6-IE8 */
        
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular');
    src: url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Regular-v2.137.woff2') format('woff2'), /* Pretty Modern Browsers */ 
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Regular-v2.137.woff') format('woff'), /* Super Modern Browsers */
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Regular-v2.137.ttf')  format('truetype'), /* Safari, Android, iOS */
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Regular-v2.137.eot'), /* IE9 Compat Modes */
        url('//uicore.dellcdn.com/#{$version}/fonts/Roboto-Regular-v2.137.eot?#iefix') format('embedded-opentype'); /* IE6-IE8 */
        
}
